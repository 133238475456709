import { useState } from "react"
import axios from 'axios'
import RST_FLAG from '../Utils/RstFlag'
import WarnningModal from '../WarnningModal'
import Alearts from '../Alearts'
import config from '../../config/config.json'

export default function Example({msg}) {

  const [warnFlag,setWarnFlag] = useState(true)
  const [contactFlag,setContactFlag] = useState(true)
  
  const openSharedocHandler = ()=> {
    console.log('host...1: ', window.location.host)
    console.log('hostname...1: ', window.location.hostname)
    console.log('pathname...1: ', window.location.pathname)
    const queryParameters = new URLSearchParams(window.location.search)
    const jwt = queryParameters.get("jwt")
    const url = `${config.API_SERVER_URL}/api/sdwb/join?jwt=${jwt}`
    console.log('open url: ', url)
    axios.get(url).then((resp)=>{
      console.log('resp: ', resp.data)
      if (resp.data.rst === RST_FLAG.SUCCESS) {
        window.location.href = resp.data.msg
      }else{
        console.log('resp.data.rst Failed:', resp.data)
        setWarnFlag(false)
        setTimeout(() => { setWarnFlag(true) }, 5000)
      }
    }).catch(err => {
      console.log('err: ', err)
      setWarnFlag(false)
      setTimeout(() => { setWarnFlag(true) }, 5000)
    })
    
  }

  const contactHandler = ()=> {
    setContactFlag(false)
    setTimeout(() => { setContactFlag(true) }, 5000)
    
  }
  return (
    <>
   
            <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-40 lg:px-8">
            <div className="text-center">
              <p className="text-base font-semibold text-indigo-600">404</p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{msg.title || 'Page not found'}</h1>
              <p className="mt-6 text-base leading-7 text-gray-600">{msg.content || 'Sorry, we couldn’t find the page you’re looking for.'}</p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  onClick={openSharedocHandler}
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Open Share Document
                </a>
                <a  className="text-sm font-semibold text-gray-900"
                  onClick={contactHandler}>
                  Contact support <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
            {warnFlag ?  null : <WarnningModal/>}
            {contactFlag ?  null : <div  className="pt-10"><Alearts msg={"Sorry, please contact our operators through email support@welltalky.com"}/></div>}
          </main>

      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}

    </>
    )
  }
  