import Styles from'./index.module.css';

export default  ( {msg} )=> {
    return(
      <>
            <div className={Styles.wrapper}>
            <div className={Styles.typingDemo}>
                {decodeURI(msg.roomName)} 即将开始...
            </div>
            </div>
        
      </>
      
    )
}
