// export default function App() {
//   return (
//     <h1 className="text-3xl font-bold underline">
//       Hello world!
//     </h1>
//   )
// }

import React, { Component } from 'react';
import Footer from "./components/Footer";
import Header from "./components/Header";
import Pagination from "./components/Pagination";
import axios from 'axios'
import { FaChevronLeft,FaChevronRight } from "react-icons/fa6";
import PageNotFound from './components/PageNotFound'
import TypingEffect from './components/TypingEffect'
// import Chandrayan from './components/Chandrayan'
import WarnningModal from './components/WarnningModal'

import RST_FLAG from './components/Utils/RstFlag'
import {jwtDecode} from 'jwt-decode'
import config from './config/config.json'
export default class App extends Component {
  constructor(props){
    super()
    this.state = {
      flag: true
    };

  }

  componentDidMount(){
    console.log('componentDidMount...')

    console.log('host...: ', window.location.host)
    console.log('hostname...: ', window.location.hostname)
    console.log('pathname...: ', window.location.pathname)
    const queryParameters = new URLSearchParams(window.location.search)
    const jwt = queryParameters.get("jwt")
    // const url = `http://localhost:5000/api/sdwb/join?jwt=${jwt}`
    const url = `${config.API_SERVER_URL}/api/sdwb/join?jwt=${jwt}`
    console.log('url: ', url)
    /**
     * 查询是否已经进入白板，在join的API中，处理是否重复登录，若重复则返回已经登录的url(带session)
     */

    setTimeout(() => {
      axios.get(url).then((resp)=>{
        console.log('resp: ', resp.data)
        if (resp.data.rst == RST_FLAG.SUCCESS) {
          window.location.href = resp.data.msg
        }else{
          this.setState({flag:false})
        }
      }).catch(err => {
        console.log('err: ', err)
        this.setState({flag:false})
        
      })
    }, 5000);

  }

    render() {
      let jwtPayload;
      const queryParameters = new URLSearchParams(window.location.search)
      const jwt = queryParameters.get("jwt")
      if (!jwt) {
        return (<PageNotFound  msg={{title:'Token Error', content: 'Token is not exist, authorization denied...'}}/>)
      }
      try {
          jwtPayload = jwtDecode(jwt);
      } catch (err) {
          console.log('err: ', err)
          return (<PageNotFound msg={{title:'Token Error', content: 'Token error, authorization denied...'}}/>)
      }
      console.log('jwtPayload: ', jwtPayload)
      const roomName = jwtPayload?.room || '会议'
      const userName = jwtPayload?.context?.user?.name || 'Hi!'
      if (this.state.flag) {
      // if (1) {
        return (
          <>
            {/* <Header/> */}
            <TypingEffect msg = {{roomName, userName}} />
            {/* <Chandrayan/> */}
            {/* <Footer/> */}
          </>
        )
      }else{
        return(
          <PageNotFound msg={{title:'Page Not Found', content: 'Sorry, we couldn’t find the page you’re looking for.'}}/>
        )
      }

  }
}
